<template>
  <el-card
    class="custom-card requisition-condition blue-soft active"
    shadow="never"
  >
    <div slot="header" class="clearfix">
      <span>Master Data Auditor (ข้อมูลส่วนตัว)</span>
    </div>
    <el-card shadow="never" class="card-inside">
      <el-row :gutter="15" type="flex" class="flex-wrap infomation">
        <el-col :span="24" :sm="12" :md="6">
          <el-form-item label="Firstname (ชื่อ)">
            {{ form.name }}
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="12" :md="6">
          <el-form-item label="Lastname (นามสกุล)">
            {{ form.surname }}
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="12" :md="6">
          <el-form-item label="Phone number (เบอร์โทร)">
            {{ form.phone }}
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="12" :md="6">
          <el-form-item label="E-mail (อีเมล)">
            {{ form.email }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </el-card>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      default: {},
    },
  },
};
</script>
