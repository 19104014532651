<template>
  <el-dialog
    :visible.sync="dialogAuditor"
    :before-close="cancelAuditor"
    width="80%"
    top="80px"
    id="dialog-auditor"
  >
    <el-form label-position="top" :model="form" ref="ruleForm" status-icon>
      <Information :form="infoCompany" />
      <FoodandISO :form="form" :type="'view'" />
      <div :gutter="15" class="is-flex ai-center js-end mg-t-4">
        <div>
          <el-button round @click="cancelAuditor()">Back</el-button>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import Information from "@/components/auditor/Information";
import FoodandISO from "@/components/auditor/edit/FoodandISO";

export default {
  props: {
    dialogAuditor: {
      type: Boolean,
      default: false,
    },
    infoCompany: {
      type: Object,
      default: {},
    },
    form: {
      type: Object,
      default: {},
    },
  },
  components: {
    Information,
    FoodandISO,
  },
  data() {
    return {};
  },
  methods: {
    cancelAuditor() {
      this.checkError = false;
      this.$emit("cancelAuditor");
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>
