<template>
  <div>
    <el-table
      :data="data"
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :class="data.length === 0 ? 'empty' : ''"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <img alt="logo" src="@/assets/logo.png" />
        <p>ไม่พบข้อมูล</p>
      </template>
      <el-table-column
        width="80"
        align="center"
        type="index"
        :index="indexMethod"
      >
        <template slot="header">
          <div class="two-line">
            <p>No.</p>
            <p>(ลำดับ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="name">
        <template slot="header">
          <div class="two-line">
            <p>Firstname</p>
            <p>(ชื่อ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="surname">
        <template slot="header">
          <div class="two-line">
            <p>Lastname</p>
            <p>(นามสกุล)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="phone">
        <template slot="header">
          <div class="two-line">
            <p>Phone number</p>
            <p>(เบอร์โทร)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="200" align="center" prop="email">
        <template slot="header">
          <div class="two-line">
            <p>E-mail</p>
            <p>(อีเมล)</p>
          </div>
        </template>
      </el-table-column>

      <!-- auditor -->
      <el-table-column
        min-width="150"
        align="center"
        prop="companyType"
        v-if="typePage == 'auditor'"
      >
        <template slot="header">
          <div class="two-line">
            <p>Type</p>
            <p>(ประเภท)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.isFood" class="color-main-1">Food safety</span>
          {{ scope.row.isFood && scope.row.isISO ? ", " : "" }}
          <span v-if="scope.row.isISO" class="color-error">ISO</span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        prop="company"
        v-if="typePage == 'auditor'"
      >
        <template slot="header">
          <div class="two-line">
            <p>Company</p>
            <p>(บริษัท)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span v-if="company.type == 2" class="color-error">
              {{ mapDataOption(company.company, optionsCompanyIso, "company") }}
            </span>

            {{
              indexS != scope.row.companyType.length - 1 && company.type == 2
                ? ", "
                : ""
            }}
            {{
              scope.row.companyType.length == 1 && company.type == 1 ? "-" : ""
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        prop="standard"
        v-if="typePage == 'auditor'"
      >
        <template slot="header">
          <div class="two-line">
            <p>Standard</p>
            <p>(มารตฐาน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span v-if="company.type == 1" class="color-main-1">
              {{
                mapDataOption(company.standard, optionsStandardFood, "standard")
              }}</span
            >
            <span v-else-if="company.type == 2" class="color-error">
              {{
                mapDataOption(
                  company.standard,
                  company.company == 1
                    ? optionsStandardIsoOne
                    : optionsStandardIsoTwo,
                  "standard"
                )
              }}
              {{ company.standard == 99 ? `(${company.other})` : "" }}
            </span>

            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        label="Accredit by "
        v-if="typePage == 'auditor'"
      >
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span v-if="company.type == 1" class="color-main-1">
              {{
                mapDataOption(
                  company.accrecditBy,
                  accrecditFood,
                  "accrecditFood"
                )
              }}</span
            >
            <span v-else-if="company.type == 2" class="color-error">
              {{
                mapDataOption(company.accrecditBy, accrecditISO, "accrecditISO")
              }}
            </span>

            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}

            <!-- <span :class="company.type == 1 ? 'color-main-1' : 'color-error'">{{
              company.catNo.join(" ")
            }}</span>
            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }} -->
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        label="CAT No."
        prop="catNo"
        v-if="typePage == 'auditor'"
      >
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span :class="company.type == 1 ? 'color-main-1' : 'color-error'">{{
              company.catNo.join(" ")
            }}</span>
            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}
          </span>
        </template>
      </el-table-column>
      <!--  -->

      <!-- Role : Board -->
      <el-table-column
        min-width="150"
        align="center"
        prop="role"
        v-if="role == 80"
      >
        <template slot="header">
          <div class="two-line">
            <p>Role</p>
            <p>(สิทธิ์การใช้งาน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span>
            {{
              scope.row.role == 80
                ? "Board"
                : scope.row.role == 60
                ? "Admin"
                : scope.row.role == 50
                ? "Auditor"
                : ""
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        align="center"
        prop="status"
        v-if="role == 80"
        class-name="is-status"
      >
        <template slot="header">
          <div class="two-line">
            <p>Status</p>
            <p>(สถานะ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <el-dropdown
            @command="(val) => handleStatus(val, scope.row._id)"
            trigger="click"
          >
            <el-button
              type="warning"
              class="button-radius"
              v-if="scope.row.status == 1"
            >
              <div class="flex-between">
                <span class="circle-status pending"></span>
                <span> รออนุมัติ </span>
                <i class="el-icon-arrow-down el-icon--right"> </i>
              </div>
            </el-button>
            <el-button
              type="not-approve"
              class="button-radius"
              v-else-if="scope.row.status == 2"
            >
              <div class="flex-between">
                <span class="circle-status not-approve"></span>
                <span> ไม่อนุมัติ </span>
                <i class="el-icon-arrow-down el-icon--right"> </i>
              </div>
            </el-button>
            <el-button
              type="approve"
              class="button-radius"
              v-else-if="scope.row.status == 3"
            >
              <div class="flex-between">
                <span class="circle-status approve"></span>
                <span> อนุมัติ </span>
                <i class="el-icon-arrow-down el-icon--right"> </i>
              </div>
            </el-button>
            <el-dropdown-menu
              slot="dropdown"
              class="dropdown-status"
              width="200"
            >
              <el-dropdown-item command="3">
                <div class="is-flex ai-center">
                  <span class="circle-status approve mg-r-3"></span>
                  <span class="font-16"> อนุมัติ </span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="2">
                <div class="is-flex ai-center">
                  <span class="circle-status not-approve mg-r-3"></span>
                  <span class="font-16"> ไม่อนุมัติ </span>
                </div></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button
            type="not-approve"
            class="button-radius"
            v-else-if="scope.row.status == 2"
          >
            <span> ไม่อนุมัติ </span>
          </el-button>
          <el-button
            type="approve"
            class="button-radius"
            v-else-if="scope.row.status == 3"
          >
            <span> อนุมัติ </span>
          </el-button> -->
        </template>
      </el-table-column>
      <el-table-column
        width="100px"
        v-if="role == 80 && typePage == 'admin'"
        align="center"
      >
        <template slot="header">
          <div class="two-line">
            <p>Action</p>
            <p>(จัดการ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="box-icon">
             <i
              class="fas fa-trash-alt"
              @click="$emit('openDialogDelete', scope.row._id)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="100px"
        v-if="role == 80 && typePage == 'auditor'"
        align="center"
      >
        <template slot="header">
          <div class="two-line">
            <p>Action</p>
            <p>(จัดการ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="box-icon">
            <i
              class="fas fa-eye"
              @click="$emit('viewAuditor', scope.row._id)"
            ></i>
            <i
              class="fas fa-edit" 
              @click="$emit('editAuditor', scope.row._id)"
            ></i>
            <i
              class="fas fa-trash-alt"
              @click="$emit('openDialogDelete', scope.row._id)"
            ></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      accrecditFood: (state) => state.optionAll.accrecditFood,
      accrecditISO: (state) => state.optionAll.accrecditISO,
    }),
  },
  props: {
    data: {
      default: Array,
      return: [],
    },
    loading: {
      type: Boolean,
      return: false,
    },
    page: {
      type: Number,
      return: 0,
    },
    limit: {
      type: Number,
      return: 0,
    },
    typePage: {
      type: String,
      return: "",
    },
    role: {
      type: Number,
      return: 0,
    },
  },
  methods: {
    indexMethod(index) {
      return this.page * this.limit - this.limit + index + 1;
    },
    handleStatus(val, id) {
      this.$emit("changeStatusUser", val, id);
    },
  },
};
</script>
