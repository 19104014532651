<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="master-data"
  >
    <div>
      <div class="is-flex ai-center js-between flex-wrap">
        <h2 class="mg-y-2 text-capitalize">Master Data {{ typePage }}</h2>
        <el-button
          v-if="user.role == 80"
          round
          @click="openHistory()"
          class="mg-t-2 font-14 button-history"
          ><i class="fas fa-history"></i> History log</el-button
        >
      </div>
      <el-row
        :gutter="15"
        class="mg-t-5 custom-filter"
        :class="user.role == 80 && typePage == 'auditor' ? 'board' : ''"
      >
        <el-form ref="formFilter" :model="filter" label-position="top">
          <FilterMasterData
            :filter="filter"
            :typePage="typePage"
            :typeMaster="typeMaster"
            :role="user.role"
            :optionsCompany="optionsCompany"
            :optionsStandard="optionsStandard"
            :optionsStatus="optionsStatus"
            :optionsAuditCycle="optionsAuditCycle"
            @selectOptionType="selectOptionType"
            @selectOptionCompany="selectOptionCompany"
            @searchMasterData="searchFilter"
          />
        </el-form>
      </el-row>
      <el-row :gutter="15" class="mg-b-5">
        <el-col :span="24">
          <TableMasterData
            v-if="typePage == 'admin' || typePage == 'auditor'"
            :data="fetchData"
            :loading="loadingTable"
            :typePage="typePage"
            :role="user.role"
            :page="page"
            :limit="limit"
            @changeStatusUser="changeStatusUser"
            @editAuditor="editAuditor"
            @viewAuditor="viewAuditor"
            @openDialogDelete="openDialogDelete"
          />
          <p class="text-right font-12 color-69">Total : {{ total }}</p>

          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="page"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <DialogEditAuditor
      :dialogAuditor="dialogAuditor"
      :infoCompany="infoCompany"
      @cancelAuditor="
        (e) => {
          e == 'finish'
            ? (((dialogAuditor = false), (infoCompany = {})), searchFilter())
            : ((dialogAuditor = false), (infoCompany = {}));
        }
      "
    />
    <DialogViewAuditor
      :dialogAuditor="dialogViewAuditor"
      :infoCompany="infoCompany"
      :form="infoCompany"
      @cancelAuditor="() => ((dialogViewAuditor = false), (infoCompany = {}))"
    />

    <el-dialog :visible.sync="dialogDelete" width="45%" top="22vh">
      <template slot="title">
        <p class="font-28 text-center mg-b-less color-main-1">ยืนยันการลบ</p>
      </template>
      <p class="text-center mg-t-less font-16 color-main-1">
        กรุณาตรวจสอบก่อนลบข้อมูล
      </p>

      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="dialogDelete = false" class="button-radius">
          <span class="font-14"> ยกเลิก </span>
        </el-button>
        <el-button
          type="warning"
          @click="submitDeleteUser()"
          class="button-radius"
        >
          <span class="font-14"> ยืนยัน </span>
        </el-button>
      </span>
    </el-dialog>
    <el-drawer
      class="drawer-history"
      :visible.sync="drawerHistory"
      :before-close="closeHistory"
    >
      <div class="box-header">
        <div>
          <span> History log </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="fetchHistory.length > 0">
        <virtual-list
          v-if="fetchHistory.length > 0"
          class="detail-log list-infinite scroll-touch"
          :data-key="'_id'"
          :data-sources="fetchHistory"
          :data-component="itemComponent"
          v-on:tobottom="scrollToBottom"
          :item-class="'list-item-infinite'"
        >
          <div slot="footer" class="text-center" v-if="loadingHistory">
            <i class="el-icon-loading"></i>
          </div>
        </virtual-list>
      </div>
      <p
        class="font-20 color-75 text-center mg-y-5 pd-t-1"
        v-if="fetchHistory.length == 0"
      >
        ไม่มีข้อมูล
      </p>
    </el-drawer>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import FilterMasterData from "@/components/filter/FilterMasterData";
import TableMasterData from "@/components/table/TableMasterData";
import DialogEditAuditor from "@/components/dialog/DialogEditAuditor";
import DialogViewAuditor from "@/components/dialog/DialogViewAuditor";

import VirtualList from "vue-virtual-scroll-list";
import History from "@/components/history/History";
import { mapState } from "vuex";

export default {
  components: {
    FilterMasterData,
    TableMasterData,
    DialogEditAuditor,
    DialogViewAuditor,
    "virtual-list": VirtualList,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      optionsAuditCycle: (state) => state.optionAll.auditCycle,
      optionsStatus: (state) => state.optionAll.status,
      typeMaster: (state) => state.typeMaster,
      imgDoc: (state) => state.imgLogoDoc,
    }),
  },
  mounted() {
    if (
      this.$route.params.typePage == "admin" ||
      this.$route.params.typePage == "auditor"
    ) {
      this.getMasterData();
    } else {
      this.$router.push(`/not-found-page`);
    }
  },
  data() {
    return {
      typePage: this.$route.params.typePage,
      loading: true,
      loadingTable: false,
      filter: {
        search: "",
        companyType: "",
        company: "",
        standard: "",
        status: "",
      },
      fetchData: [],
      optionsType: [],
      optionsCompany: [],
      optionsStandard: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      dialogAuditor: false,
      dialogViewAuditor: false,
      idActive: "",
      infoCompany: {},
      formView: {},
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,

      drawerHistory: false,
      fetchHistory: [],
      loadingHistory: false,
      totalAll: 0,
      skipHistory: 0,
      limitHistory: 10,
      pageNowHistory: 1,
      itemComponent: History,
      dialogDelete: false,
    };
  },
  methods: {
    async getMasterData() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      let obj = {
        ...this.filter,
        skip: this.skip,
        limit: this.limit,
        typePage: this.typePage == "admin" ? 1 : 2,
      };
      HTTP.post(`master/data/information`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
            let setData = [];
            res.data.obj.forEach(async (row) => {
              let setobj = {
                ...row,
                companyType: row.companyType.sort((a, b) => {
                  let modifier = 1;
                  if (a.type < b.type) return -1 * modifier;
                  if (a.type > b.type) return 1 * modifier;
                  return 0;
                }),
                isFood:
                  row.companyType.find((row) => row.type == 1) != undefined
                    ? true
                    : false,
                isISO:
                  row.companyType.find((row) => row.type == 2) != undefined
                    ? true
                    : false,
              };
              setData.push(setobj);
            });
            this.fetchData = setData;
            this.total = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
      // this.getMasterData();
    },
    changePage(page) {
      this.loadingTable = true;
      this.pageNow = page;
      this.skip = page * this.limit - this.limit;
      this.getMasterData();
    },
    searchFilter() {
      this.loadingTable = true;
      if (this.filter.search == "") {
        this.skip = 0;
        this.limit = 10;
      }
      this.getMasterData();
    },
    selectOptionType(valType) {
      this.optionsStandard = [];
      this.optionsCompany = [];
      const findByType = this.typeMaster.find((f) => f.typeNum == valType);

      if (valType == 1) {
        this.optionsStandard = findByType.standard;
        this.filter.company = "";
      } else if (valType == 2) {
        this.optionsCompany = findByType.company;
        this.filter.standard = "";
      } else {
        this.filter.company = "";
        this.filter.standard = "";
      }
      this.searchFilter();
    },
    selectOptionCompany(valCompany) {
      this.filter.standard = "";

      this.optionsStandard = [];

      if (valCompany) {
        const findByCompany = this.optionsCompany.find(
          (f) => f.companyNum == valCompany
        );
        this.optionsStandard = findByCompany.standard;
      } else {
        this.filter.company = "";
        this.filter.standard = "";
      }
      this.searchFilter();
    },
    changeStatusUser(val, id) {
      this.loadingTable = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        userId: id,
        status: Number(val),
      };
      HTTP.post(`master/data/change/status`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "แก้ไขสถานะสำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.getMasterData();
          } else {
            this.loadingTable = false;
            this.$notify({
              title: "แก้ไขสถานะไม่สำเร็จ!",
              type: "error",
              customClass: "error",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    async getinformationAuditor(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`auditor/` + id)
        .then(async (res) => {
          if (res.data.success) {
            let filterFood = await res.data.obj.companyType
              .filter((row) => row.type == 1)
              .map((mapRow) => {
                // เพิ่ม File List
                let arr = [];
                if (mapRow.files.length > 0) {
                  mapRow.files.forEach((files) => {
                    let setUrl = this.imageUrl + "auditor/" + id + "/" + files;
                    let typeThis = files.split(".");
                    if (typeThis[typeThis.length - 1] == "pdf") {
                      setUrl = this.imgDoc.pdf;
                    } else if (
                      typeThis[typeThis.length - 1] == "docx" ||
                      typeThis[typeThis.length - 1] == "doc"
                    ) {
                      setUrl = this.imgDoc.docx;
                    } else if (
                      typeThis[typeThis.length - 1] == "xlsx" ||
                      typeThis[typeThis.length - 1] == "xls" ||
                      typeThis[typeThis.length - 1] == "csv"
                    ) {
                      setUrl = this.imgDoc.xlsx;
                    } else if (
                      typeThis[typeThis.length - 1] == "ppt" ||
                      typeThis[typeThis.length - 1] == "pptx"
                    ) {
                      setUrl = this.imgDoc.ppt;
                    } else if (typeThis[typeThis.length - 1] == "zip") {
                      setUrl = this.imgDoc.zip;
                    }

                    arr.push({
                      typeUpload: 1,
                      name: files,
                      url: setUrl,
                    });
                  });
                }

                return { ...mapRow, customFileList: arr, valCatFoodSafety: "" };
              });
            let filterISO = await res.data.obj.companyType
              .filter((row) => row.type == 2)
              .map((mapRow) => {
                // เพิ่ม File List
                let arr = [];
                if (mapRow.files.length > 0) {
                  mapRow.files.forEach((files) => {
                    let setUrl = this.imageUrl + "auditor/" + id + "/" + files;
                    let typeThis = files.split(".");
                    if (typeThis[typeThis.length - 1] == "pdf") {
                      setUrl = this.imgDoc.pdf;
                    } else if (
                      typeThis[typeThis.length - 1] == "docx" ||
                      typeThis[typeThis.length - 1] == "doc"
                    ) {
                      setUrl = this.imgDoc.docx;
                    } else if (
                      typeThis[typeThis.length - 1] == "xlsx" ||
                      typeThis[typeThis.length - 1] == "xls" ||
                      typeThis[typeThis.length - 1] == "csv"
                    ) {
                      setUrl = this.imgDoc.xlsx;
                    } else if (
                      typeThis[typeThis.length - 1] == "ppt" ||
                      typeThis[typeThis.length - 1] == "pptx"
                    ) {
                      setUrl = this.imgDoc.ppt;
                    } else if (typeThis[typeThis.length - 1] == "zip") {
                      setUrl = this.imgDoc.zip;
                    }
                    arr.push({
                      typeUpload: 1,
                      name: files,
                      url: setUrl,
                    });
                  });
                }

                return { ...mapRow, customFileList: arr, valCatISO: "" };
              });
            this.infoCompany = {
              ...res.data.obj,
              checkboxFood: filterFood.length > 0 ? true : false,
              checkboxISO: filterISO.length > 0 ? true : false,
              conditionsFood: await filterFood,
              conditionsISO: await filterISO,
              delFilesCondition: [],
            };
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    async editAuditor(id) {
      this.dialogAuditor = true;
      this.getinformationAuditor(id);
    },
    viewAuditor(id) {
      this.dialogViewAuditor = true;
      this.getinformationAuditor(id);
    },
    openHistory() {
      this.drawerHistory = true;
      this.getHistoryLog();
    },
    getHistoryLog(skip, limit, type) {
      let obj = {
        type: this.typePage == "admin" ? 6 : 7,
        skip: skip != undefined ? skip : this.skipHistory,
        limit: limit != undefined ? limit : this.limitHistory,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/history/manage`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.fetchHistory = res.data.obj;
            } else {
              res.data.obj.forEach((row) => {
                this.fetchHistory.push({ ...row });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },

    closeHistory() {
      this.drawerHistory = false;
      this.skipHistory = 0;
      this.pageNowHistory = 1;
    },
    async scrollToBottom() {
      if (this.fetchHistory.length < this.totalAll) {
        this.skipHistory = this.pageNowHistory * this.limitHistory;
        this.loadingHistory = true;
        await this.getHistoryLog(this.skipHistory, this.limitHistory, "scroll");
        this.pageNowHistory++;
      } else {
        this.loadingHistory = false;
      }
    },

    openDialogDelete(id) {
      this.idActive = id;
      this.dialogDelete = true;
    },
    submitDeleteUser() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.delete(`user/delete/` + this.idActive)
        .then((res) => {
          if (res.data.success) {
            const setType = this.typePage == "auditor" ? "Auditor" : "Admin";

            this.$notify({
              title: `ลบ Master Data ${setType} สำเร็จ!`,
              type: "success",
              customClass: "success",
            });
            this.loadingTable = true;
            this.idActive = "";
            this.dialogDelete = false;
            this.getMasterData();
          }
        })
        .catch((e) => {
          console.log("fetchTest", e);
        });
    },
  },
};
</script>
