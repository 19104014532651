<template>
  <el-dialog
    :visible.sync="dialogAuditor"
    :before-close="cancelAuditor"
    width="80%"
    top="80px"
    id="dialog-auditor"
  >
    <template slot="title">
      <p class="font-28 mg-b-less">แก้ไข</p>
    </template>
    <el-form label-position="top" :model="form" ref="ruleForm" status-icon>
      <Information :form="infoCompany" />
      <FoodandISO
        :form="form"
        :type="'add'"
        @checkError="checkboxChangeError"
        @addCondition="addCondition"
        @delCondition="delCondition"
      />
      <div class="font-error mg-t-2" v-if="checkError">กรุณาเลือก ประเภท</div>
      <div :gutter="15" class="is-flex ai-center js-between mg-t-4">
        <div>
          <el-button round @click="cancelAuditor()">Back</el-button>
        </div>

        <el-button
          type="warning"
          class="button-radius font-14"
          :loading="loadingSubmit"
          @click="submitAuditor()"
          >Save</el-button
        >
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import Information from "@/components/auditor/Information";
import FoodandISO from "@/components/auditor/edit/FoodandISO";

import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  props: {
    dialogAuditor: {
      type: Boolean,
      default: false,
    },
    infoCompany: {
      type: Object,
      default: {},
    },
  },
  components: {
    Information,
    FoodandISO,
  },
  watch: {
    infoCompany() {
      this.setData();
    },
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  data() {
    return {
      loadingSubmit: false,
      checkError: false,
      form: {},
      delCompany: [],
      setUpload: [],
    };
  },
  methods: {
    setData() {
      this.delCompany = [];
      this.setUpload = [];
      this.form = this.infoCompany;
    },
    cancelAuditor(e) {
      this.checkError = false;
      this.$emit("cancelAuditor", e);
      this.$refs["ruleForm"].resetFields();
    },
    async submitAuditor() {
      this.$refs["ruleForm"].validate((valid) => {
        if (this.form.checkboxFood == false && this.form.checkboxISO == false) {
          this.checkError = true;
          this.loadingSubmit = false;
        } else if (valid) {
          let companyType = [];

          if (this.form.conditionsFood.length > 0) {
            this.form.conditionsFood.forEach((row, index) => {
              let setArr = {
                _id: row._id,
                type: row.type,
                company: row.company,
                standard: row.standard,
                other: row.other,
                accrecditBy: row.accrecditBy,
                catNo: row.catNo,
              };

              if (row.files.length > 0) {
                row.files.forEach((forFile) => {
                  if (forFile.file != undefined && forFile.file != null) {
                    this.setUpload.push({
                      companyTypeId: row._id,
                      companyIndex: index,
                      file: forFile.file,
                      type: 1,
                    });
                  }
                });
              }

              companyType.push(setArr);
            });
          }
          if (this.form.conditionsISO.length > 0) {
            this.form.conditionsISO.forEach((row, index) => {
              let setArr = {
                _id: row._id,
                type: row.type,
                company: row.company,
                standard: row.standard,
                other: row.other,
                accrecditBy: row.accrecditBy,
                catNo: row.catNo,
              };
              if (row.files.length > 0) {
                row.files.forEach((forFile) => {
                  if (forFile.file != undefined && forFile.file != null) {
                    this.setUpload.push({
                      companyTypeId: row._id,
                      companyIndex: index,
                      file: forFile.file,
                      type: 2,
                    });
                  }
                });
              }

              companyType.push(setArr);
            });
          }
          let obj = {
            companyType: companyType,
            delCompany: this.delCompany,
          };

          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.put(`auditor/edit/` + this.form._id, obj)
            .then(async (res) => {
              if (res.data.success) {
                this.$notify({
                  title: "แก้ไขข้อมูลสำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
                if (this.setUpload.length > 0) {
                  await this.uploadFiles(res.data.obj);
                }
                if (this.form.delFilesCondition.length > 0) {
                  await this.deleteFiles();
                }
                this.cancelAuditor("finish");
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                }
              }
            });
          this.loadingSubmit = false;
        } else {
          this.loadingSubmit = false;
        }
      });
    },
    addCondition(type) {
      if (type == "foodSafety") {
        this.form.conditionsFood.push({
          company: "",
          type: 1,
          standard: "",
          other: "",
          accrecditBy: "",
          catNo: [],
          files: [],
          customFileList: [],
          valCatFoodSafety: "",
        });
      } else if (type == "iso") {
        this.form.conditionsISO.push({
          company: "",
          type: 2,
          standard: "",
          other: "",
          accrecditBy: "",
          catNo: [],
          files: [],
          customFileList: [],
          valCatISO: "",
        });
      }
    },
    delCondition(type, index, id) {
      if (id != undefined && id != null) {
        this.delCompany.push(id);
      }
      if (type == "foodSafety") {
        this.form.conditionsFood.splice(index, 1);
      } else if (type == "iso") {
        this.form.conditionsISO.splice(index, 1);
      }
    },
    checkboxChangeError(val, type) {
      this.checkError = val;

      if (this.form.checkboxFood == false) {
        if (this.form.conditionsFood.length > 0) {
          this.form.conditionsFood.forEach((condition) => {
            if (condition._id != undefined && condition._id != null) {
              this.delCompany.push(condition._id);
            }
          });
          this.form.conditionsFood = [];
        }
      } else if (
        this.form.checkboxFood == true &&
        type == "foodSafety" &&
        this.form.conditionsFood.length == 0
      ) {
        this.addCondition("foodSafety");
      }

      if (this.form.checkboxISO == false) {
        if (this.form.conditionsISO.length > 0) {
          this.form.conditionsISO.forEach((condition) => {
            if (condition._id != undefined && condition._id != null) {
              this.delCompany.push(condition._id);
            }
          });
          this.form.conditionsISO = [];
        }
      } else if (
        this.form.checkboxISO == true &&
        type == "iso" &&
        this.form.conditionsISO.length == 0
      ) {
        this.addCondition("iso");
      }
    },
    async uploadFiles(data) {
      this.setUpload = this.setUpload.map((upload) => {
        let setComId = "";
        if (upload.companyTypeId == undefined && upload.companyTypeId == null) {
          if (upload.type == 1) {
            let findTypeOne = data.companyType.filter(
              (comType) => comType.type == 1
            );
            setComId = findTypeOne[upload.companyIndex]._id;
          } else if (upload.type == 2) {
            let findTypeTwo = data.companyType.filter(
              (comType) => comType.type == 2
            );
            setComId = findTypeTwo[upload.companyIndex]._id;
          }
        } else {
          setComId = upload.companyTypeId;
        }
        return {
          file: upload.file,
          companyTypeId: setComId,
          userId: this.form._id,
        };
      });

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };

      var formData = new FormData();
      if (this.setUpload.length > 0) {
        formData.append("userId", this.form._id);

        this.setUpload.forEach((e) => {
          formData.append("companyTypeId", e.companyTypeId);
          formData.append("filesUpload", e.file.raw);
          formData.append("filesName", e.file.name);
        });
      }
      formData.append("_csrf", this.setCsrf);

      await HTTP.post(`auditor/files`, formData, config)
        .then(async (res) => {
          if (res.data.success) {
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    async deleteFiles() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      await HTTP.post(`auditor/delete/files`, {
        userId: this.form._id,
        delFiles: this.form.delFilesCondition,
      })
        .then((res) => {
          if (res.data.success) {
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
  },
};
</script>
