<template>
  <div>
    <el-card
      class="custom-card requisition-condition blue"
      :class="form.checkboxFood ? 'active' : ''"
      shadow="never"
    >
      <div slot="header" class="clearfix">
        <el-checkbox
          v-if="type == 'add'"
          v-model="form.checkboxFood"
          @change="
            $emit(
              'checkError',
              form.checkboxFood == false && form.checkboxISO == false
                ? true
                : false,
              'foodSafety'
            )
          "
        ></el-checkbox>
        <span>ตรวจ Food Safety</span>
      </div>
      <div v-if="form.checkboxFood">
        <el-card
          shadow="never"
          v-for="(conditions, indexC) in form.conditionsFood"
          :key="indexC"
          class="card-inside"
        >
          <el-row :gutter="15" type="flex" class="flex-wrap">
            <el-col :span="24" :md="12">
              <el-form-item
                label="Standard (มาตรฐาน)"
                :prop="'conditionsFood.' + indexC + '.standard'"
                :rules="{
                  required: true,
                  message: 'กรุณาเลือก Standard',
                  trigger: ['change'],
                }"
              >
                <span v-if="type == 'view'">
                  {{
                    mapDataOption(
                      conditions.standard,
                      optionsStandardFood,
                      "standard"
                    )
                  }}
                </span>
                <el-select
                  v-else
                  filterable
                  clearable
                  v-model="conditions.standard"
                  placeholder="Standard"
                  no-match-text="ไม่พบข้อมูลที่ค้นหา"
                >
                  <el-option
                    v-for="(item, index) in optionsStandardFood"
                    :key="index"
                    :label="item.standardName"
                    :value="item.standardNum"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="Accredit By"
                :prop="'conditionsFood.' + indexC + '.accrecditBy'"
                :rules="{
                  required: true,
                  message: 'กรุณาเลือก Accredit By',
                  trigger: ['change'],
                }"
              >
                <span v-if="type == 'view'">
                  {{
                    mapDataOption(conditions.accrecditBy, optionAccrecditFood)
                  }}
                </span>
                <el-select
                  v-else
                  filterable
                  clearable
                  v-model="conditions.accrecditBy"
                  placeholder="Accredit By"
                  no-match-text="ไม่พบข้อมูลที่ค้นหา"
                >
                  <el-option
                    v-for="(item, index) in optionAccrecditFood"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="CAT No."
                :prop="'conditionsFood.' + indexC + '.catNo'"
                :rules="{
                  type: 'array',
                  required: true,
                  validator: conditions.catNo.length == 0 ? validateCat : '',
                  trigger: ['change'],
                }"
              >
                <div v-if="type == 'view'">
                  <span :key="indexS" v-for="(tag, indexS) in conditions.catNo">
                    {{ tag }}
                  </span>
                </div>
                <el-input
                  v-else
                  v-model="conditions.valCatFoodSafety"
                  placeholder="CAT No."
                  @keypress.native="isNumber($event)"
                  @keyup.native.enter="
                    addCatNo(indexC, 'foodSafety', conditions.valCatFoodSafety)
                  "
                >
                  <el-button
                    slot="append"
                    icon="el-icon-plus"
                    @click="
                      addCatNo(
                        indexC,
                        'foodSafety',
                        conditions.valCatFoodSafety
                      )
                    "
                  ></el-button>
                </el-input>
              </el-form-item>
              <div v-if="type != 'view'">
                <el-tag
                  :key="indexS"
                  v-for="(tag, indexS) in conditions.catNo"
                  closable
                  :disable-transitions="false"
                  @close="delCatNo(indexC, indexS, 'foodSafety')"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="Upload File (อัปโหลดไฟล์ครั้งละไม่เกิน 25 MB)"
                :class="type == 'view' ? 'view-only' : ''"
                :prop="'conditionsFood.' + indexC + '.files'"
                :rules="{
                  type: 'array',
                  required: true,
                  message: 'กรุณา Upload File',
                  trigger: ['change'],
                }"
              >
                <el-upload
                  drag
                  multiple
                  action="#"
                  :auto-upload="false"
                  :on-change="
                    (file, fileList) =>
                      uploadDocuments(
                        file,
                        'foodSafety',
                        indexC,
                        conditions._id,
                        fileList
                      )
                  "
                  :on-remove="
                    (file) => handleRemoveDocuments(file, 'foodSafety', indexC)
                  "
                  list-type="picture"
                  :file-list="conditions.customFileList"
                  :on-preview="(file) => perviewFile(file)"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-if="indexC > 0 && type != 'view'"
            class="del-condition"
            @click="$emit('delCondition', 'foodSafety', indexC, conditions._id)"
          >
            <i class="fas fa-trash-alt"></i>
          </div>
        </el-card>
        <el-button
          type="blue-dark"
          class="button-radius font-14 w-100 mg-t-4"
          @click="$emit('addCondition', 'foodSafety')"
          v-if="type != 'view'"
        >
          <i class="fas fa-plus"></i>
          เพิ่มการตรวจ Food Safety</el-button
        >
      </div>
    </el-card>
    <el-card
      class="custom-card requisition-condition blue-light"
      :class="form.checkboxISO ? 'active' : ''"
      shadow="never"
    >
      <div slot="header" class="clearfix">
        <el-checkbox
          v-if="type == 'add'"
          v-model="form.checkboxISO"
          @change="
            $emit(
              'checkError',
              form.checkboxFood == false && form.checkboxISO == false
                ? true
                : false,
              'iso'
            )
          "
        ></el-checkbox>
        <span>ตรวจ ISO</span>
      </div>
      <div v-if="form.checkboxISO">
        <el-card
          shadow="never"
          v-for="(conditions, indexISO) in form.conditionsISO"
          :key="indexISO"
          class="card-inside"
        >
          <el-row :gutter="15" type="flex" class="flex-wrap">
            <el-col :span="24" :md="12">
              <el-form-item
                label="Company (บริษัท)"
                :prop="'conditionsISO.' + indexISO + '.company'"
                :rules="{
                  required: true,
                  message: 'กรุณากรอก Company (บริษัท)',
                  trigger: 'blur',
                }"
              >
                <span v-if="type == 'view'">
                  {{
                    mapDataOption(
                      conditions.company,
                      optionsCompanyIso,
                      "company"
                    )
                  }}
                </span>
                <el-select
                  v-else
                  filterable
                  clearable
                  v-model="conditions.company"
                  placeholder="Company (บริษัท)"
                  no-match-text="ไม่พบข้อมูลที่ค้นหา"
                  @change="conditions.standard = ''"
                >
                  <el-option
                    v-for="(item, index) in optionsCompanyIso"
                    :key="index"
                    :label="item.companyName"
                    :value="item.companyNum"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="Standard (มาตรฐาน)"
                :prop="'conditionsISO.' + indexISO + '.standard'"
                :rules="{
                  required: true,
                  message: 'กรุณาเลือก Standard',
                  trigger: ['change'],
                }"
              >
                <span v-if="type == 'view'">
                  {{
                    mapDataOption(
                      conditions.standard,
                      conditions.company == 1
                        ? optionsStandardIsoOne
                        : optionsStandardIsoTwo,
                      "standard"
                    )
                  }}
                  {{ conditions.standard == 99 ? `(${conditions.other})` : "" }}
                </span>
                <el-row v-else :gutter="15" type="flex" class="flex-wrap">
                  <el-col :span="24" :md="conditions.standard == 99 ? 12 : 24">
                    <el-select
                      filterable
                      clearable
                      v-model="conditions.standard"
                      placeholder="Standard"
                      no-match-text="ไม่พบข้อมูลที่ค้นหา"
                      :disabled="!conditions.company ? true : false"
                    >
                      <el-option
                        v-for="(item, index) in conditions.company == 1
                          ? optionsStandardIsoOne
                          : optionsStandardIsoTwo"
                        :key="index"
                        :label="item.standardName"
                        :value="item.standardNum"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="24" :md="12" v-if="conditions.standard == 99">
                    <el-form-item
                      class="mg-b-less"
                      :prop="'conditionsISO.' + indexISO + '.other'"
                      :rules="{
                        required: true,
                        message: 'กรุณากรอก',
                        trigger: ['change'],
                      }"
                    >
                      <el-input v-model="conditions.other"> </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="Accredit By"
                :prop="'conditionsISO.' + indexISO + '.accrecditBy'"
                :rules="{
                  required: true,
                  message: 'กรุณาเลือก Accredit By',
                  trigger: ['change'],
                }"
              >
                <span v-if="type == 'view'">
                  {{
                    mapDataOption(conditions.accrecditBy, optionAccrecditISO)
                  }}
                </span>
                <el-select
                  v-else
                  filterable
                  clearable
                  v-model="conditions.accrecditBy"
                  placeholder="Accredit By"
                  no-match-text="ไม่พบข้อมูลที่ค้นหา"
                >
                  <el-option
                    v-for="(item, index) in optionAccrecditISO"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="CAT No."
                :prop="'conditionsISO.' + indexISO + '.catNo'"
                :rules="{
                  type: 'array',
                  required: true,
                  validator: conditions.catNo.length == 0 ? validateCatISO : '',
                  trigger: ['change'],
                }"
              >
                <div v-if="type == 'view'">
                  <span :key="indexS" v-for="(tag, indexS) in conditions.catNo">
                    {{ tag }}
                  </span>
                </div>
                <el-input
                  v-else
                  v-model="conditions.valCatISO"
                  placeholder="CAT No."
                  @keypress.native="isNumber($event)"
                  @keyup.native.enter="
                    addCatNo(indexISO, 'iso', conditions.valCatISO)
                  "
                >
                  <el-button
                    slot="append"
                    icon="el-icon-plus"
                    @click="addCatNo(indexISO, 'iso', conditions.valCatISO)"
                  ></el-button>
                </el-input>
              </el-form-item>
              <div v-if="type != 'view'">
                <el-tag
                  :key="indexS"
                  v-for="(tag, indexS) in conditions.catNo"
                  closable
                  :disable-transitions="false"
                  @close="delCatNo(indexISO, indexS, 'iso')"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="Upload File (อัปโหลดไฟล์ครั้งละไม่เกิน 25 MB)"
                :class="type == 'view' ? 'view-only' : ''"
                :prop="'conditionsISO.' + indexISO + '.files'"
                :rules="{
                  type: 'array',
                  required: true,
                  message: 'กรุณา Upload File',
                  trigger: ['change'],
                }"
              >
                <el-upload
                  drag
                  multiple
                  action="#"
                  :auto-upload="false"
                  :on-change="
                    (file) =>
                      uploadDocuments(file, 'iso', indexISO, conditions._id)
                  "
                  :on-remove="
                    (file) => handleRemoveDocuments(file, 'iso', indexISO)
                  "
                  list-type="picture"
                  :file-list="conditions.customFileList"
                  :on-preview="(file) => perviewFile(file)"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-if="indexISO > 0 && type != 'view'"
            class="del-condition"
            @click="$emit('delCondition', 'iso', indexISO, conditions._id)"
          >
            <i class="fas fa-trash-alt"></i>
          </div>
        </el-card>
        <el-button
          type="blue-light"
          class="button-radius font-14 w-100 mg-t-4"
          @click="$emit('addCondition', 'iso')"
          v-if="type != 'view'"
        >
          <i class="fas fa-plus"></i>
          เพิ่มการตรวจ ISO</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      optionAccrecditFood: (state) => state.optionAll.accrecditFood,
      optionAccrecditISO: (state) => state.optionAll.accrecditISO,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
      imgDoc: (state) => state.imgLogoDoc,
    }),
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    var validateCats = (rule, value, callback) => {
      if (this.valCatFoodSafety == "") {
        callback(new Error("กรุณากรอก CAT No."));
      } else {
        callback();
      }
    };
    var validateCatISOs = (rule, value, callback) => {
      if (this.valCatISO == "") {
        callback(new Error("กรุณากรอก CAT No."));
      } else if (this.valCatISO != "") {
        callback(new Error("กรุณากรอก CAT No."));
      } else {
        callback();
      }
    };
    return {
      validateCat: validateCats,
      validateCatISO: validateCatISOs,
      valCatFoodSafety: "",
      valCatISO: "",
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {
    addCatNo(index, type, valueThis) {
      if (valueThis != "" && type == "foodSafety") {
        this.form.conditionsFood[index].catNo.push(valueThis);
        this.form.conditionsFood[index].valCatFoodSafety = "";
      }
      if (valueThis != "" && type == "iso") {
        this.form.conditionsISO[index].catNo.push(valueThis);
        this.form.conditionsISO[index].valCatISO = "";
      }
    },
    delCatNo(index, tag, type) {
      if (type == "foodSafety") {
        this.form.conditionsFood[index].catNo.splice(tag, 1);
      } else if (type == "iso") {
        this.form.conditionsISO[index].catNo.splice(tag, 1);
      }
    },

    uploadDocuments(file, typeByUpload, indexC, companyId, fileListUp) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        let setUrl = URL.createObjectURL(file.raw);
        if (file.size < 25000000) {
          if (typeThis[indexOfFile] == "pdf") {
            setUrl = this.imgDoc.pdf;
          } else if (
            typeThis[indexOfFile] == "docx" ||
            typeThis[indexOfFile] == "doc"
          ) {
            setUrl = this.imgDoc.docx;
          } else if (
            typeThis[indexOfFile] == "xlsx" ||
            typeThis[indexOfFile] == "xls" ||
            typeThis[indexOfFile] == "csv"
          ) {
            setUrl = this.imgDoc.xlsx;
          } else if (
            typeThis[indexOfFile] == "ppt" ||
            typeThis[indexOfFile] == "pptx"
          ) {
            setUrl = this.imgDoc.ppt;
          } else if (typeThis[indexOfFile] == "zip") {
            setUrl = this.imgDoc.zip;
          }
          let obj = {
            name: file.name,
            file: file,
            companyId: companyId,
            indexCompany: indexC,
            typeOffile:
              typeByUpload == "foodSafety" ? 1 : typeByUpload == "iso" ? 2 : "",
            url: setUrl,
          };
          // this.setUpload.push(obj);
          if (typeByUpload == "foodSafety") {
            this.form.conditionsFood[indexC].files.push(obj);
            this.form.conditionsFood[indexC].customFileList.push(obj);
          } else if (typeByUpload == "iso") {
            this.form.conditionsISO[indexC].files.push(obj);
            this.form.conditionsISO[indexC].customFileList.push(obj);
          }
        } else {
          let index = fileListUp.findIndex((a) => a.uid == file.uid);
          fileListUp.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        this.form.fileList = JSON.parse(JSON.stringify(this.form.fileList));
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleRemoveDocuments(file, type, indexC) {
      let findIndex = null;

      if (file.typeUpload == 1) {
        this.form.delFilesCondition.push({
          name: file.name,
          companyId:
            type == "foodSafety"
              ? this.form.conditionsFood[indexC]._id
              : type == "iso"
              ? this.form.conditionsISO[indexC]._id
              : "",
        });
      }

      if (type == "foodSafety") {
        findIndex = this.form.conditionsFood[indexC].customFileList.findIndex(
          (f) => f.file != undefined && f.uid == file.uid
        );
        if (findIndex < 0) {
          findIndex = this.form.conditionsFood[indexC].files.findIndex(
            (f) => f == file.name
          );
        }

        this.form.conditionsFood[indexC].files.splice(findIndex, 1);
        this.form.conditionsFood[indexC].customFileList.splice(findIndex, 1);
      } else if (type == "iso") {
        findIndex = this.form.conditionsISO[indexC].customFileList.findIndex(
          (f) => f.file != undefined && f.uid == file.uid
        );
        if (findIndex < 0) {
          findIndex = this.form.conditionsISO[indexC].files.findIndex(
            (f) => f == file.name
          );
        }
        this.form.conditionsISO[indexC].files.splice(findIndex, 1);
        this.form.conditionsISO[indexC].customFileList.splice(findIndex, 1);
      }
    },
    perviewFile(file) {
      if (file.raw == undefined && file.raw == null) {
        window.open(
          this.imageUrl + "auditor/" + this.form._id + "/" + file.name
        );
      } else {
        window.open(URL.createObjectURL(file.raw));
      }
    },
  },
};
</script>
